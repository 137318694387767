<template>
  <div class="web-detail">
    <hall-detail-header  :model="lessonInfo" title="课程预览" ></hall-detail-header>
    <div class="section-list" >
      <hall-detail-section :width="80">
        <img slot="icon" src="@/assets/web/22.png" alt="" srcset="" />
        <p
          class="section-target-content"
          v-html="sectionInfo.sectionTarget"
          slot="content"
        ></p>
      </hall-detail-section>
      <hall-detail-section :width="83">
        <img slot="icon" src="@/assets/web/23.png" alt="" srcset="" />
        <div class="before" slot="content">
          <div v-for="(val, key) in beforeObj" :key="key">
            <div v-if="key == 'default'" style="display: flex; flex-wrap: wrap">
              <file
                v-for="(item, index) in val"
                :key="item.AppendixId"
                :modal="item"
                :canDelete="false"
                :index="index"
              ></file>
            </div>
            <div v-else>
              <after-class-source :title="key">
                <div slot="content" style="display: flex; flex-wrap: wrap">
                  <file
                    v-for="(item, index) in val"
                    :key="item.AppendixId"
                    :modal="item"
                    :index="index"
                    :canDelete="false"
                  ></file>
                </div>
              </after-class-source>
            </div>
          </div>
        </div>
      </hall-detail-section>
      <hall-detail-section :width="86" :rev="true">
        <img slot="icon" src="@/assets/web/24.png" alt="" srcset="" />
        <div class="mid" slot="content">
            <div class="actions-list">
                <lesson-action
                  v-for="(item, index) in actionList"
                  :key="item.actionId"
                  :modal="item"
                  :index="index"
                ></lesson-action>
            </div>

            <!-- 课中工具 -->
            <lesson-design-header
              soltPadding="30px 15px 10px 30px"
              bgColor="#EDF0F5"
            >
              <img slot="icon" src="@/assets/gj.png" alt="" srcset="" />
              <div slot="content">
                <div v-for="(val, key) in midObj" :key="key">
                  <div
                    v-if="key == 'default'"
                    style="display: flex; flex-wrap: wrap"
                  >
                    <file
                      v-for="(item, index) in val"
                      :key="item.AppendixId"
                      :modal="item"
                      :index="index"
                      :canDelete="false"
                    ></file>
                  </div>
                  <div v-else>
                    <after-class-source :title="key">
                      <div
                        slot="content"
                        style="display: flex; flex-wrap: wrap"
                      >
                        <file
                          v-for="(item, index) in val"
                          :key="item.AppendixId"
                          :modal="item"
                          :index="index"
                          :canDelete="false"
                        ></file>
                      </div>
                    </after-class-source>
                  </div>
                </div>
              </div>
            </lesson-design-header>
          </div>
      </hall-detail-section>
      <hall-detail-section :width="83" :rev="true">
        <img slot="icon" src="@/assets/web/25.png" alt="" srcset="" />
        <div class="after" slot="content">
            <div v-for="(val, key) in afterObj" :key="key">
              <div
                v-if="key == 'default'"
                style="display: flex; flex-wrap: wrap"
              >
                <file
                  v-for="(item, index) in val"
                  :key="item.AppendixId"
                  :modal="item"
                  :index="index"
                  :canDelete="false"
                ></file>
              </div>
              <div v-else>
                <after-class-source :title="key" bgColor="rgb(237, 240, 245)">
                  <div slot="content" style="display: flex; flex-wrap: wrap">
                    <file
                      v-for="(item, index) in val"
                      :key="item.AppendixId"
                      :modal="item"
                      :index="index"
                      :canDelete="false"
                    ></file>
                  </div>
                </after-class-source>
              </div>
            </div>
          </div>
      </hall-detail-section>
    </div>
    <div style="height:80px;margin-top:50px;">
        <to-top></to-top>
    </div>
  </div>
</template>

<script>
import HallDetailHeader from "../../components/website/hallDetail/HallDetailHeader.vue";
import { getLessonInfo } from "@/api/website";
import HallDetailSection from "../../components/website/hallDetail/HallDetailSection.vue";
import File from "../../components/File.vue";
import AfterClassSource from "../../components/AfterClassSource.vue";
import LessonAction from '../../components/website/hallDetail/LessonAction.vue';
import LessonDesignHeader from '../../components/LessonDesignHeader.vue';
import ToTop from '../../components/website/ToTop.vue';
export default {
  components: { HallDetailHeader, HallDetailSection, File, AfterClassSource, LessonAction, LessonDesignHeader, ToTop },
  data() {
    return {
      lessonInfo: {
        LessonName: "",
        FrontCover: "",
        LessonDesc: "",
        Author: "",
      },
      sectionInfo: {
        sectionName: "",
        sectionTitle: "",
        sectionTarget: "",
        lessonTimeInfo: [],
      },
      beforeObj: {},
      midObj: {},
      afterObj: {},
      actionList:[]
    };
  },
  mounted() {
    this.requestInfo();
  },
  methods: {
    requestInfo() {
      getLessonInfo({ lessonId: this.$route.query.lessonId }).then((res) => {
        console.log(res);
        this.sectionInfo = res.data.lessonInfo.sectionInfo;
        this.lessonInfo = res.data.lessonInfo.lessonInfo
        this.sectionInfo.lessonTimeInfo.forEach((element) => {
          if (element.name == "课中") {
            let action = element;
            this.actionList = action.actionList;
            this.midObj = this.insertToolByGroup(action.appendix);
          } else if (element.name == "课前") {
            this.beforeObj = this.insertToolByGroup(element.appendix);
          } else if (element.name == "课后") {
            let after = element.appendix || [];
            this.afterObj = this.insertToolByGroup(element.appendix);
          }
        });
      });
    },
    /**
     * 工具分组
     */
    insertToolByGroup(list) {
      let obj = {
        default: [],
      };
      list.forEach((item) => {
        if (item.ApxTag.length > 0) {
          obj[item.ApxTag] = obj[item.ApxTag] || [];
          obj[item.ApxTag].push(item);
        } else {
          obj.default.push(item);
        }
      });
      return obj;
    },
  },
};
</script>

<style lang="less">
.web-detail {
    background: #F3F3F3;
    
    .section-list{
        padding-top: 15Px;
        width: 96%;
        background: white;
        margin: 0 auto;
        padding-bottom: 50Px;
    }
    .section-target-content {
        padding: 80Px;
        word-break: break-all;
        white-space: pre-wrap;
        font-size: 14Px;
        img {
            width: 100%;
        }
    }
    .before {
        padding: 50Px;
    }
    .mid {
        padding: 40Px 60Px;
        .actions-list {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .after {
        padding: 30Px 50Px;
        
    }
}
</style>