<template>
  <div class="after-class-source">
    <div class="after-source-title-warp">
      <span class="after-source-title">
        {{ title }}
      </span>
    </div>
    <div class="after-class-content"  :style="{background:bgColor}">
      <slot name="content" />
    </div>

    <a-popover title="" placement="topRight" trigger="click" :visible="visible">
      <template slot="content">
        <div class="tool-warp">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="上传文件">
              <br />
              <a-upload
                name="file"
                action="/api/MyLesson/UploadFiles"
                :file-list="fileList"
                @change="uploadHandleChange"
              >
                <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
              </a-upload>
              <br />
              <div>
                <a-input
                  addon-before="教学用途"
                  placeholder="请输入文件教学用途"
                  v-model="filename"
                />
              </div>
              <br />
              <div style="text-align: right">
                <a-button type="default" @click="cancelUpload">取消</a-button>
                <span style="padding: 8px"></span>
                <a-button type="primary" @click="saveFile">保存</a-button>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="文件链接" force-render>
              <br />
              <div>
                <a-input
                  addon-before="文件链接"
                  placeholder="请粘贴文件链接"
                  v-model="link"
                />
              </div>
              <br />
              <div>
                <a-input
                  addon-before="教学用途"
                  placeholder="请输入文件教学用途"
                  v-model="linkname"
                />
              </div>
              <br />
              <div style="text-align: right">
                <a-button type="default" @click="cancelUpload">取消</a-button>
                <span style="padding: 8px"></span>
                <a-button type="primary" @click="saveLink">保存</a-button>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </template>
      <img
        v-if="canUpload"
        src="@/assets/big-add.png"
        @click="edit"
        class="edit-btn"
        alt=""
        srcset=""
      />
    </a-popover>
  </div>
</template>

<script>
import afterFlag from "@/assets/after-flag.png";
export default {
  name: "after-class-source",
  props: {
    title: {
      type: String,
      default: "课后练习",
    },
    canUpload: {
      type: Boolean,
      default: false,
    },
    bgColor:{
        type:String,
        default: '#ffffff'
    }
  },
  data() {
    return {
      afterFlag: afterFlag,
      visible: false,
      fileList: [],
      filename: "",
      linkname: "",
      link: "",
    };
  },
  methods: {
    edit() {
      this.visible = true;
    },
    /**
     * 上传文件
     */
    uploadHandleChange(info) {
      // if (info.file.status !== "uploading") {
      //   console.log(info.file, info.fileList);
      //   this.fileList = info.fileList
      // }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} 文件上传成功`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 文件上传失败`);
      }

      let fileList = [...info.fileList];
      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);
      // 2. read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
    },
    /**
     * 保存文件
     */
    saveFile() {
      if (this.fileList.length == 0) {
        this.$message.error("请上传文件");
        return;
      }
      if (this.filename.length == 0) {
        this.$message.error("请输入文件的教学用途（文件名称）");
        return;
      }
      let file = this.fileList[0].response[0];
      let params = {
        purpose: this.filename,
        ...file,
      };
      this.$emit("uploadFile", params);
      this.fileList = [];
      this.filename = "";
      this.visible = false;
    },
    /**
     * 保存链接
     */
    saveLink() {
      if (this.link.length == 0) {
        this.$message.error("请输入资源链接");
        return;
      }
      if (this.linkname.length == 0) {
        this.$message.error("请输入资源链接的教学用途（链接名称）");
        return;
      }
      let params = {
        linkUrl: this.link,
        purpose: this.linkname,
      };
      this.$emit("uploadLink", params);
      this.link = "";
      this.linkname = "";
      this.visible = false;
    },
    cancelUpload() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less">
.after-class-source {
    font-size: 15Px;
    margin-bottom: 30Px;
    position: relative;
    text-align: center;
    padding-top: 20Px;
    .after-source-title-warp {
        position: absolute;
        top: -0Px;
        width: 100%;
        display: flex;
        justify-content: center;
        .after-source-title {
            color: #2A2A31;
            padding: 8Px 24Px;
            font-size: 14Px;
            background: #FFFFFF;
            box-shadow: 0px 5px 10px rgba(110, 118, 255, 0.53);
            opacity: 1;
            border-radius: 24px;
        }
    }

    .after-class-content {
        background: white;
        padding: 30Px;
        padding-bottom: 10Px;
        border-radius: 6Px;
        padding-left: 30Px;
        min-height: 60Px;
    }
}
</style>