<template>
  <div class="lesson-design-action">
    <!-- 编辑模块名称和颜色 -->
    <div class="action-title" :style="{ background: modal.bgColor }">
      {{ modal.actionName }}
    </div>

    <div class="design-content">

      <!-- 目标输入框 -->
      <textarea
        class="action-target"
        rows="2"
        maxlength="20"
        placeholder="请输入文字"
        :value="modal.actionTarget"
      ></textarea>

      <!-- 上传图片 -->
      <div class="upload-warp">
          <img
          v-if="modal.actionFrontCover"
            :src="modal.actionFrontCover"
            alt="pic"
            class="poster"
          />
      </div>

      <!-- 已选工具 -->
      <div class="tool-title">已选工具</div>
      <ul class="design-tools">
        <file-small
          v-for="(item, index) in modal.appendix"
          :key="item.AppendixId"
          :modal="item"
          :index="index"
          @preview="previewSource"
        ></file-small>
      </ul>
    </div>
  </div>
</template>

<script>
import FileSmall from "../../FileSmall.vue";
import { delAliYunFile } from "@/api/user";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const colors = [
  "#81B4FF",
  "#9EA3FF",
  "#FFCA64",
  "#FFC9AC",
  "#FF5700",
  "#84E2D9",
  "#E3E3E6",
];
export default {
  components: { FileSmall },
  name: "lesson-action",
  props: {
    modal: {
      type: Object,
      default: () => {
        return {
          actionFrontCover: "",
          actionId: "",
          actionName: "",
          actionSort: "",
          actionTarget: "",
          appendix: [],
          missingAppendix: [],
          bgColor: "#E3E3E6",
          fontColor: "#2A2A31",
        };
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
      loading: false,
      target: "",
      colors: colors,
      color: "",
      currentTabKey: "1",
      fileList: [],
      filename: "",
      linkname: "",
      link: "",
      visibleToolUpload: false,
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    /**
     * 上传资料
     */
    handleOk(e) {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    /**
     * 取消上传
     */
    handleCancel(e) {
      console.log("Clicked cancel button");
      this.visible = false;
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("文件大小必须小于500MB!");
      }
      return isLt2M;
    },
    /**
     * 上传文件
     */
    uploadHandleChange(info) {
      // if (info.file.status !== "uploading") {
      //   console.log(info.file, info.fileList);
      //   this.fileList = info.fileList
      // }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} 文件上传成功`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 文件上传失败`);
      }

      let fileList = [...info.fileList];
      if (fileList.length > 1) {
        this.removeFile(fileList[0]);
      }
      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);
      // 2. read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
    },
    /**
     * 删除工具
     */
    deleteTool(params) {
      console.log(params);
      params.pindex = this.index;
      this.$emit("toolDelete", params);
    },
    /**
     * 恢复删除的工具
     */
    recoveryAction(params) {
      params.pindex = this.index;
      this.$emit("recoveryTool", params);
    },
    /**
     * 永久删除工具
     */
    permanentlyDelete(params) {
      params.pindex = this.index;
      this.$emit("permanently", params);
    },
    removeFile(obj) {
      delAliYunFile({ key: obj.response[0].SourceUrl });
    },
    /**
     * 删除整个模块
     */
    confirmDeleteAction() {
      let model = { ...this.modal };
      model.index = this.index;
      this.$emit("actionDelete", model);
    },
    /**
     * 上传图片
     */
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        let url = info.file.response[0].SourceUrl;
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.$emit("uploadPic", {
            actionId: this.modal.actionId,
            url: url,
            base64: imageUrl,
            index: this.index,
          });
          this.loading = false;
        });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("你只能上传jpg或者png的图片");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片大小必须小于2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    /**
     * 目标失去焦点
     */
    targetBlur(e) {
      console.log(e.target.value);
      let value = e.target.value;
      if (value.length == 0) {
        return;
      }
      this.$emit("editTarget", {
        actionId: this.modal.actionId,
        newValue: value,
        filed: "target",
        index: this.index,
      });
    },
    /**
     * 选择了模块的颜色
     */
    selectColor(color) {
      this.color = color;
      this.$emit("editColor", {
        index: this.index,
        newValue: color,
        actionId: this.modal.actionId,
        filed: "bgColor",
      });
    },
    /**
     * 保存模块名称
     */
    saveActionName(value) {
      console.log(value);
      this.$emit("editName", {
        index: this.index,
        newValue: value,
        filed: "name",
        actionId: this.modal.actionId,
      });
    },
    /**
     * 保存文件
     */
    saveFile() {
      if (this.fileList.length == 0) {
        this.$message.error("请上传文件");
        return;
      }
      let file = this.fileList[0].response[0];
      let params = {
        index: this.index,
        actionId: this.modal.actionId,
        purpose: this.filename,
        ...file,
      };
      this.$emit("uploadFile", params);
      this.fileList = [];
      this.filename = "";
      this.visibleToolUpload = false;
    },
    /**
     * 保存链接
     */
    saveLink() {
      if (this.link.length == 0) {
        this.$message.error("请输入资源链接");
        return;
      }
      if (this.linkname.length == 0) {
        this.$message.error("请输入资源链接的教学用途（链接名称）");
        return;
      }
      let params = {
        index: this.index,
        actionId: this.actionId,
        linkUrl: this.link,
        purpose: this.linkname,
      };
      this.$emit("uploadLink", params);
      this.link = "";
      this.linkname = "";
      this.visibleToolUpload = false;
    },
    previewSource(params) {
      params.pindex = this.index;
      this.$emit("preview", params);
    },
    previewMissApx(params) {
      params.pindex = this.index;
      this.$emit("previewmiss", params);
    },
  },
};
</script>

<style lang="less">
.edit-action-title-content {
  width: 300px;
  min-height: 180px;
  .colors {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 10px;
    .color {
      width: 30px;
      height: 30px;
      border: 1Px solid white;
      margin-right: 10px;
      cursor: pointer;
    }
    .color-select {
      border: 1Px solid @primary-color;
    }
  }
}
.tool-warp {
  width: 450Px;
  min-height: 220Px;
}
.lesson-design-action {
  position: relative;
  width: 240px;
  height: 420px;
  margin-right: 25px;
  margin-bottom: 25Px;
  .action-title {
    width: 150px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #e3e3e6;
    opacity: 1;
    border-radius: 10px;
    position: absolute;
    left: 0;
    z-index: 2;
    top: 2px;
    right: 0;
    margin: auto;
    overflow: hidden;
    text-overflow:ellipsis;
  }
  .delete-action {
      position: absolute;
      right: 40px;
      width: 20px;
      height: 20px;
      z-index: 4;
      cursor: pointer;
    }
  .design-content {
    width: 240px;
    height: 402px;
    border: 1px dotted #8e8e93;
    opacity: 1;
    border-radius: 10px;
    position: absolute;
    top: 20px;
    z-index: 1;
    .tool-title {
      position: absolute;
      width: 110px;
      height: 40px;
      bottom: 118px;
      left: 0;
      right: 0;
      margin: auto;
      background: #edf0f5;
      text-align: center;
      font-size: 15px;
      border-radius: 6px;
      padding-top: 4px;
    }

    .action-target {
      margin: 0;
      text-align: center;
      display: block;
      // height: 50px;
      border: none;
      outline: none;
      margin-top: 44px;
      font-size: 15px;
      padding: 0 10px;
      width: 230px;
      border: none;
      resize: none;

    }

    .upload-warp {
        text-align: center;
        display: flex;
        justify-content: center;
      .poster {
        width: 156px;
        height: 102px;
      }
    }
    
    .design-tools {
      padding: 0;
      margin: 0;
      position: absolute;
      left: 5px;
      right: 5px;
      bottom: 5px;
      height: 120px;
      background: #edf0f5;
      overflow: hidden;
    }
  }
  .add {
    cursor: pointer;
    position: absolute;
    right: -8px;
    bottom: -10px;
    z-index: 10;
    width: 40Px;
    height: 40Px;
  }
  .insert {
      cursor: pointer;
      position: absolute;
      right: 0;
      width: 30px;
      height: 30px;
      top: 0;
      bottom: 0;
      margin: auto;
      img {
          width: 30px;
          height: 30px;
      }
      .insert-title {
          font-size: 12px;
          color: @primary-color;
          width: 40px;
          position: relative;
          left: -2px;
          top: 2px;
      }
  }
}
</style>