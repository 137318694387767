<template>
  <li class="tool-item">
    <div class="file-name-warp">
      <span class="file-title" @click.prevent="download">
        <img class="file-icon" :src="fileType(modal.ApxUrl)" alt="" />
        <span class="name"
          >【{{ modal.ApxFileAnthorName || modal.ApxFileName }}】</span
        >
      </span>
    </div>
  </li>
</template>

<script>
import { fileType } from "@/utils/util";
const whiteTypeList = ["doc", "xls", "unknown"];
export default {
  name: "file-small",
  props: {
    modal: {
      type: Object,
      default: () => {
        return {
          AppendixId: 0,
          ApxFileName: "",
          ApxUrl: "xxx.pdf",
          ApxFileAnthorName: "",
        };
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    confirmDelete() {
      this.$emit("delete", { apxId: this.modal.AppendixId, index: this.index });
    },
    fileType: fileType,
    download() {
      this.$emit('preview', {index:this.index})
    },
  },
};
</script>

<style lang="less">
.tool-item {
    padding: 6px;
    padding-bottom: 2Px;
    cursor: pointer;
    .file-name-warp {
        display: flex;
        align-items: center;
        .file-title {
        position: relative;
        display: flex;
        align-items: center;
        .file-icon {
            width: 12Px;
            height: 12Px;
        }
        .name {
            font-size: 12Px;
            height: 16Px;
            overflow: hidden;
            max-width: 190px;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
        }
    }
    }
</style>