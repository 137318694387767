<template>
  <div class="hall-detail-section">
    <div class="detail-section-content">
      <div class="icon-warp">
        <slot name="icon"/>
      </div>
      <div class="solt-content" :style="{width:`${width}%`}">
          <slot name="content" />
      </div>
    </div>
    <div class="btm-warp">
        <div class="content-bottom-r" v-if="rev" :style="{width: `${width}%`}"></div>
        <div class="content-bottom" v-else :style="{width: `${width+3}%`}"></div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        width:{
            type: Number,
            default: 63
        },
        rev:{
            type:Boolean,
            default: false
        }
    }
};
</script>

<style lang="less">
.hall-detail-section {
    background: white;
    .detail-section-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .solt-content {
            border-top: 1Px solid #E0E0E0;
            border-left: 1Px solid #E0E0E0;
            min-height: 30Px;
        }
        .icon-warp {
            margin-right: 110Px;
            width: 60Px;
            height: 50Px;
            img {
                width: 60Px;
            }
        }
        
    }
    .btm-warp {
        display: flex;
        justify-content: flex-end;
        .content-bottom {
            width: 1000Px;
            height: 36Px;
            background: #E0E0E0;
            // position: relative;
        }
        .content-bottom::before {
            content: '';
            height: 0Px;
            width: 0Px;
            display: block;
            border: 18Px solid transparent;
            border-top-color: white;
            border-left-color: white;
        }
        .content-bottom-r {
            width: 1000Px;
            height: 36Px;
            background: #E0E0E0;
            // position: relative;
        }
        .content-bottom-r::before {
            content: '';
            height: 0Px;
            width: 0Px;
            display: block;
            border: 18Px solid transparent;
            border-bottom-color: white;
            border-left-color: white;
        }
    }
}
</style>